import React, { useState } from 'react';

import { wrapper, form, title as titleClass, successOutput } from './user-newsletter.module.scss';
import { INavLink } from '../../models/nav-link.model';
import { ISection } from '../../models/section.model';
import { IUserData } from '../../models/user.model';
import { newsletterConsentForm } from '../../formik/newsletter-consent.form';
import { useUser } from '../../hooks/use-user';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import useTranslations from '../../hooks/use-translations';

import UserLayout from '../../layouts/user-layout';
import Title from '../atoms/title';
import FormGenerator from '../molecules/form-generator';

export interface IUserNewsletterSection extends ISection {
    items: {
        links: INavLink[];
        newsletter: {
            newsletterId: number;
        };
    };
}

export interface IUserNewsletterProps {
    className?: string;
    section: IUserNewsletterSection;
    TitleTag?: React.ElementType;
}

export default function UserNewsletter({
    className = '',
    section,
    TitleTag = 'h2',
}: IUserNewsletterProps) {
    const t = useTranslations('UserNewsletter');
    const { sectionId, css, style } = section;
    const [subtitle, consentContent] = section.content.texts;
    const { links, newsletter } = section.items;
    const user = useUser({ dontSkip: true });
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(user.toggleNewsletter.errors);
    const initialValues = createInitialValues(newsletter.newsletterId, user.data);
    const [visibleSuccessOutput, setVisibleSuccessOutput] = useState(false);

    const handleSubmit = async ({ consent }: { consent: boolean }) => {
        if (!user.data) return;

        const body = {
            newsletter: newsletter.newsletterId,
            email: user.data?.email,
        };

        try {
            await user.toggleNewsletter
                .fetch({
                    ...body,
                    action: consent ? 'subscribe' : 'unsubscribe',
                    approval1: consent,
                })
                .unwrap();
            await user.get.refetch().unwrap();
            setVisibleSuccessOutput(true);

            setTimeout(() => {
                setVisibleSuccessOutput(false);
            }, 3000);
        } catch {}
    };

    return (
        <UserLayout
            sectionId={sectionId}
            className={className}
            links={links}
            css={css}
            style={style}
            childrenWrapperClassName={wrapper}
        >
            <Title Tag={TitleTag} className={titleClass}>
                {subtitle}
            </Title>
            <FormGenerator
                formRef={formikRef}
                formikProps={{
                    onSubmit: handleSubmit,
                    initialValues: initialValues,
                    enableReinitialize: true,
                }}
                name="NewsletterConsentForm"
                fields={newsletterConsentForm}
                extraFieldsProps={{ consentContent }}
                formClassName={form}
                errorMessage={globalErrorMessage}
                submitLoading={user.toggleNewsletter.isLoading || user.get.isFetching}
                submitOnlyOnChange={true}
            >
                {visibleSuccessOutput && <output className={successOutput}>{t.success}</output>}
            </FormGenerator>
        </UserLayout>
    );
}

function createInitialValues(newsletterId: number, user?: IUserData | null) {
    return {
        consent: user?.newsletters?.find((newsletter) => newsletter.newsletterId === newsletterId)
            ? true
            : false ?? '',
        email: user?.email ?? '',
    };
}
